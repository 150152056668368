import React from "react";
import { Helmet } from "react-helmet";

const Metadata: React.FC = () => {
  return (
    <Helmet>
      <title>Minerva Gate</title>
      <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no" />
      <meta name="title" content="Minerva Gate - We talk product, not just code!" />
      <meta
        name="description"
        content="We are a digital agency that provides fast and scalable software solutions for your business to thrive. We are generalists by nature, specializing in everything that's web & mobile based, including UX/UI Design, Full-Stack Development, QA and Service Deployment."
      />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="Minerva Gate - We talk product, not just code!" />
      <meta property="og:url" content="https://minervagate.com/" />
      <meta
        property="og:description"
        content="We are a digital agency that provides fast and scalable software solutions for your business to thrive. We are generalists by nature, specializing in everything that's web & mobile based, including UX/UI Design, Full-Stack Development, QA and Service Deployment."
      />
      <meta property="og:image" content="https://drive.google.com/uc?id=1wQNMseNz2JnzDsVIKacoqDAbz8mfqcc0" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://minervagate.com/" />
      <meta property="twitter:title" content="Minerva Gate - We talk product, not just code!" />
      <meta
        property="twitter:description"
        content="We are a digital agency that provides fast and scalable software solutions for your business to thrive. We are generalists by nature, specializing in everything that's web & mobile based, including UX/UI Design, Full-Stack Development, dApp development, AI solutions, Quality Assurance and Service Deployment."
      />
      <meta property="twitter:image" content="https://drive.google.com/uc?id=1wQNMseNz2JnzDsVIKacoqDAbz8mfqcc0" />
      <script src="https://www.google.com/recaptcha/api.js?render=6LelkxMiAAAAAFZbz_OKMmTuLwpLkxQpYRclPH_-"/>
    </Helmet>
  );
};

export default Metadata;
